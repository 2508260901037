import { useState } from 'react';

import styled from 'styled-components';

const Tooltip = ({ children, content }) => {
	const [isVisible, setIsVisible] = useState(false);

	const showTooltip = () => setIsVisible(true);
	const hideTooltip = () => setIsVisible(false);

	if (!content) return null;

	return (
		<Wrapper onMouseEnter={showTooltip} onMouseLeave={hideTooltip} onClick={showTooltip}>
			{children ?? <TooltipIcon className="fal fa-info-circle" />}

			{isVisible && <TooltipBox>{content}</TooltipBox>}
		</Wrapper>
	);
};

export default Tooltip;

const Wrapper = styled.div`
	position: relative;
	display: inline-block;
	cursor: pointer;
`;

const TooltipBox = styled.div`
	position: absolute;
	bottom: 120%;
	left: 50%;
	width: 200px;
	padding: 8px 12px;
	font-size: 13px;
	font-weight: 500;
	text-align: center;
	white-space: normal;
	word-wrap: break-word;
	border: 1px solid #757575;
	border-radius: 5px;
	color: #3b3f45;
	background-color: #f3f4f6;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	transform: translateX(-50%) scale(0.5);
	animation: scale-extreme 0.3s ease-out forwards;

	@media (min-width: 768px) {
		width: max-content;
		max-width: 400px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		border-width: 5px;
		border-style: solid;
		border-color: #757575 transparent transparent transparent;
	}

	@keyframes scale-extreme {
		0% {
			transform: translateX(-50%) scale(0.5);
			opacity: 0;
		}
		50% {
			transform: translateX(-50%) scale(1.1);
			opacity: 1;
		}
		100% {
			transform: translateX(-50%) scale(1);
			opacity: 1;
		}
	}
`;

export const TooltipIcon = styled.span`
	font-size: 24px;
	color: #a1a1aa;
	text-shadow: 1px 1px 0 #334155;
	cursor: pointer;
`;
