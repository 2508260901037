import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

import { getFeaturesList } from 'utils/planFeaturesHelpers';

const defaultFeatures = {
	Plus: ['Remove Branding', 'Sound Notifications'],
	Pro: [
		'Remove Branding',
		'Sound Notifications',
		'Mapping Add-ons',
		'Send as WhatsApp, Email, SMS'
	],
	Growth: [
		'Remove Branding',
		'Sound Notifications',
		'Mapping Add-ons',
		'Send as WhatsApp, Email, SMS'
	]
};

const IncludedFeatures = ({ plan, customFeatures, heading = 'Included:' }) => {
	const features = customFeatures || [
		...getFeaturesList(plan),
		...(defaultFeatures[plan?.name] || [])
	];

	if (!Array.isArray(features) || !features.length) return null;

	return (
		<Features>
			<h3>{heading}</h3>

			<FeaturesList features={features} />
		</Features>
	);
};

const FeaturesList = ({ features }) => (
	<ul>
		{features.map((feature) => (
			<li key={feature} title={feature}>
				<FaIcon icon="check" type="duotone" size="12px" /> {feature}
			</li>
		))}
	</ul>
);

export default IncludedFeatures;

const Features = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	color: #4b5563;

	h3 {
		margin: 0;
		padding: 0;
		font-size: 0.9rem;
		font-weight: 600;
	}

	ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin: 0;
		padding: 0;

		li {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			padding-left: 2px;
			font-size: 13px;
			font-weight: 400;

			i::after {
				margin-right: 2px;
				color: #60a5fa;
			}
		}
	}
`;
