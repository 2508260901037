import { Button } from './style';

export const SelectButton = ({ planData, activePlan, selectedPlan, onSelectPlan }) => {
	const { id, name, accessType, isFree } = planData || {};

	const { id: activePlanId, cancelDate } = activePlan || {};

	const isActivePlan = activePlanId === id;
	const isInteracted = selectedPlan?.id === id;
	const isPrivate = accessType === 'private';

	return (
		<Button
			planName={name}
			isPrivate={isPrivate}
			isActive={isActivePlan}
			isInteracted={isInteracted}
			disabled={cancelDate && isFree}
			onClick={onSelectPlan}
			aria-label={`Select ${name} plan`}
		>
			{getButtonText(isInteracted, isActivePlan, isFree, cancelDate)}
		</Button>
	);
};

const getButtonText = (isInteracted, isActivePlan, isFreePlan, cancelDate) => {
	if (isActivePlan) {
		if (cancelDate) return 'Your Plan / Reactivate';

		return 'Your Plan';
	}

	if (isFreePlan) {
		if (cancelDate) return 'Pending Free Plan';

		return 'Downgrade to Free';
	}

	if (isInteracted) return 'Selected';

	return 'Select';
};
