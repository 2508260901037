import Tooltip from 'components/Tooltip';

import { formatCurrency } from 'utils/formatter';

import IncludedFeatures from './IncludedFeatures';
import * as Styles from './style';

export const PlanInfoCard = ({ planData, activePlan }) => {
	const { id, name, accessType, isFree, perMonth, perMonthAnn, jobsLimit } = planData || {};
	const { id: activePlanId, cancelDate } = activePlan || {};

	const isActive = activePlanId === id;

	if (isFree) {
		return (
			<Styles.PlanInfoCard planName={name} isFree={isFree} isActive={isActive}>
				<Styles.Name>{name}</Styles.Name>

				<Styles.FreePlanDescription>
					<span>Try BizPrint free!</span>

					<p>You get {jobsLimit} prints free and then you need to upgrade</p>
				</Styles.FreePlanDescription>
			</Styles.PlanInfoCard>
		);
	}

	const priceTermLabel = getPriceTermLabel(perMonth, perMonthAnn);

	const isPrivate = accessType === 'private';
	const isPopular = name === 'Pro';
	const isCanceling = cancelDate != null && isActive;

	return (
		<Styles.PlanInfoCard planName={name} isPrivate={isPrivate} isActive={isActive}>
			<PlanBadgeContent isCanceling={isCanceling} isPopular={isPopular} isPrivate={isPrivate} />

			<Styles.Name>{name}</Styles.Name>

			<Styles.Price aria-label={priceTermLabel} title={priceTermLabel}>
				{formatCurrency(perMonthAnn || perMonth)}

				<sub>
					/mo <sup>* †</sup>
				</sub>
			</Styles.Price>

			<IncludedFeatures plan={planData} />

			{isCanceling && <PlanExpirationNotice cancelDate={cancelDate} />}
		</Styles.PlanInfoCard>
	);
};

const PlanBadgeContent = ({ isCanceling, isPopular, isPrivate }) => {
	const badges = {
		expiring: { condition: isCanceling, type: 'expiring', label: '🕒️ Expires Soon' },
		popular: { condition: isPopular, type: 'popular', label: '🔥 Most Popular' },
		personal: { condition: isPrivate, type: 'personal', label: 'Plan Just for You' }
	};

	const activeBadge = Object.values(badges).find((badge) => badge.condition);

	if (!activeBadge) return null;

	return <Styles.PlanBadge badgeType={activeBadge.type}>{activeBadge.label}</Styles.PlanBadge>;
};

const PlanExpirationNotice = ({ cancelDate }) => {
	return (
		<Styles.ExpirationWrapper>
			<div>
				Plan set to end on
				<br /> <strong>{cancelDate}</strong>
			</div>

			<Tooltip content="Once your subscription ends, you’ll be moved to the Trial plan." />
		</Styles.ExpirationWrapper>
	);
};

const getPriceTermLabel = (perMonth, perMonthAnn) => {
	if (perMonthAnn != null && perMonth != null) {
		return 'Annual term. Monthly pricing available';
	}

	if (perMonthAnn != null) return 'Annual term';

	if (perMonth != null) return 'Monthly term';

	return '';
};
