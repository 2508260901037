import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DashboardItem from 'components/ui/DashBoardItem';
import FaIcon from 'components/ui/Icons';

export const ItemLink = styled(Link)`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0 1rem 1.5rem;
	text-decoration: none;
	color: black;
`;

export const CreateLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	max-width: 100px;
	border: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	font-size: 3rem;
	color: #fff;
	background-color: #2d9631;
	text-decoration: none;

	&:hover {
		background-color: #3ea742;
		cursor: pointer;
	}
`;

const colorSchemes = {
	Private: { color: '#ff5d5b', bgColor: '#fef2f2' },
	Trial: { color: '#7bc2d5', bgColor: '#f8fafc' },
	Basics: { color: '#a0befa', bgColor: '#eff6ff' },
	Plus: { color: '#b49efa', bgColor: '#f5f3ff' },
	Pro: { color: '#ffac33', bgColor: '#fff7ed' },
	Growth: { color: '#26a69a', bgColor: '#e0f2f1' },
	default: { color: 'rgba(0, 0, 0, 0.2)', bgColor: '#ffffff' }
};

export const getColorScheme = (planName, isPrivate) => {
	return isPrivate ? colorSchemes.Private : colorSchemes[planName] || colorSchemes.default;
};

export const ItemContainer = styled(DashboardItem)`
	flex-flow: row;
	padding: 0;

	&:nth-child(2) a {
		padding: 0;
	}

	${({ planName, isPrivate }) => {
		const { color, bgColor } = getColorScheme(planName, isPrivate);

		return css`
			background-color: ${bgColor};
			box-shadow: 0 0 2px 1px ${color};
		`;
	}}
`;

export const ItemHeader = styled.div`
	display: flex;
	align-items: center;

	h2 {
		font-size: 1.1rem;
		font-weight: normal;
		margin: 0;
	}
`;

export const ItemIcon = styled(FaIcon)`
	font-size: 34px;
	margin-right: 1rem;
`;
